import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Navigate } from 'react-router-dom'
import { useGetOne, usePermissions } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBandAid,
    faCartPlus,
    faHistory,
    faIdCardAlt,
    faMailBulk,
    faMoneyCheckAlt,
    faReceipt,
    faRuler,
    faSortNumericUpAlt,
    faStoreAlt,
    faTable,
    faGaugeHigh,
    faHouseUser,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import decodeJwt from 'jwt-decode'
import md5 from 'md5'

import PermissionHandler from '../../config/PermissionHandler'
import InfoPopup from '../Popup/InfoPopup'
import BannerSwiper from '../BannerSwiper/BannerSwiper'
import ChartSwiper from '../ChartSwiper/ChartSwiper'
import { Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import ContactFormModal from '../Contact/ContactFormModal'

const menuElementContainer = {
    display: 'flex',
    width: '100%',
    minWidth: 250,
    minHeight: 100,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const fontStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

const fontStyleBlocked = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '0.75rem',
}
const chartBox = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: 600,
    minHeight: 300,
    maxHeight: 400,
    margin: 5,
    padding: 15,
    gap: '20px',
}

const containerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
}

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    const { data, loading, error } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    var token = localStorage.getItem('token')
    try {
        var decodedToken = decodeJwt(token)
    } catch (e) {}
    var username = decodedToken?.username
    let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)

    if (
        !isLoading &&
        data?.firstName == 'Temporary' &&
        data?.lastName == 'User'
    ) {
        return (
            <Card>
                <CardContent>
                    <div className="page-content m-2">
                        <div className="row">
                            {PermissionHandler(
                                permissions,
                                'Workers',
                                'Read'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/workers"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faIdCardAlt}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Pracownicy
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {PermissionHandler(
                                permissions,
                                'Dashboard',
                                'ContactForm'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/contact-form"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMailBulk}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Formularz kontaktowy
                                        </span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }

    return data && !isLoading ? (
        <>
            {data.isRegulationChecked === false && !data.departmentPermissions ? (
                <Navigate to="/mydata" />
            ) : null}
            {PermissionHandler(permissions, 'InfoPopup', 'Read') &&
                !localStorage.getItem('infoPopupShowed14112024') &&
                ["TZMO", "SMP", "Citonet Szczecin"].includes(data?.partnerName) &&
                // data?.payer &&
                // JSON.parse(data.payer)?.partnerName == 'TZMO' && 
                <InfoPopup />}
            <Card>
                <CardContent>
                    {PermissionHandler(
                        permissions,
                        'Dashboard',
                        'BannerSwiper'
                    ) && (
                        <div style={chartBox}>
                            <BannerSwiper />
                            <ChartSwiper />
                        </div>
                    )}

                    <div className="page-content m-2">
                        {PermissionHandler(
                            permissions,
                            'Dashboard',
                            'CanBuy'
                        ) && (
                            <div className="row">
                                <div className="col">
                                    <a
                                        href={
                                            data.shopUrl +
                                            '/superlogin.php?hash=' +
                                            hashMd5 +
                                            '&email=' +
                                            username +
                                            '&token=' +
                                            token +
                                            '&timestamp=' +
                                            Date.now() +
                                            '&redirect=true'
                                        }
                                        target="_blank"
                                        style={menuElementContainer}
                                        className={`dash-card ${
                                            data.isBlocked &&
                                            'dash-card-disabled'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon={faCartPlus} />
                                        {data.isBlocked == true ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    minWidth: '500px',
                                                }}
                                            >
                                                <span style={fontStyle}>
                                                    Złóż zamówienie
                                                </span>

                                                <p style={fontStyleBlocked}>
                                                    Uwaga, posiadasz zaległe
                                                    płatności. Obecnie składanie
                                                    zamówień nie jest możliwe.
                                                    <br />
                                                    Ureguluj zaznaczone
                                                    dokumenty lub skontaktuj się
                                                    z przedstawicielem TZMO
                                                </p>
                                            </div>
                                        ) : (
                                            <span style={fontStyle}>
                                                Złóż zamówienie
                                            </span>
                                        )}
                                    </a>
                                </div>
                                <div className="col">
                                    <Link
                                        to="/fast-order/new"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faGaugeHigh}
                                            inverse
                                            shake
                                            mask="fa-light"
                                        />
                                        <span style={fontStyle}>
                                            Szybkie zamówienie
                                        </span>
                                    </Link>
                                </div>
                                {PermissionHandler(
                                    permissions,
                                    'IndividualOrders',
                                    'Create'
                                ) && data?.isIndividualOrderAvailable && (
                                    <div className="col">
                                        <Link
                                            to="/individual_orders/create"
                                            style={menuElementContainer}
                                            className="dash-card"
                                        >
                                            <FontAwesomeIcon
                                                icon={faHouseUser}
                                                inverse
                                                mask="fa-light"
                                            />
                                            <span style={fontStyle}>
                                                Zamówienie do pacjenta
                                            </span>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="row">
                            {PermissionHandler(
                                permissions,
                                'Orders',
                                'Read'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/orders"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faHistory}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Historia zamówień
                                        </span>
                                    </Link>
                                </div>
                            )}

                            {PermissionHandler(
                                permissions,
                                'Invoices',
                                'Read'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/invoices"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMoneyCheckAlt}
                                            inverse
                                        />
                                        <span style={fontStyle}>Faktury</span>
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div className="row">
                            {PermissionHandler(
                                permissions,
                                'PickupPoints',
                                'Read'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/pickup_points"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faStoreAlt}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Punkt odbioru
                                        </span>
                                    </Link>
                                </div>
                            )}
                            {PermissionHandler(
                                permissions,
                                'Workers',
                                'Read'
                            ) && (
                                <div className="col">
                                    <Link
                                        to="/workers"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faIdCardAlt}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Pracownicy
                                        </span>
                                    </Link>
                                </div>
                            )}
                            <div className="col">
                                <Link
                                    to="/mydata"
                                    style={menuElementContainer}
                                    className="dash-card"
                                >
                                    <FontAwesomeIcon icon={faTable} inverse />
                                    <span style={fontStyle}>Moje dane</span>
                                </Link>
                            </div>
                        </div>

                        {PermissionHandler(
                            permissions,
                            'Dashboard',
                            'ComplaintRow'
                        ) && (
                            <div className="row">
                                <div className="col">
                                    <Link
                                        to="/reclamations"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faSortNumericUpAlt}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Reklamacje
                                        </span>
                                    </Link>
                                </div>
                                <div className="col">
                                    <Link
                                        to="/refundation-menu"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faReceipt}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Dofinansowanie NFZ
                                        </span>
                                    </Link>
                                </div>

                                <div className="col">
                                    <a
                                        href="https://seni.pl/pl_PL/tresc/dobor-rozmiaru"
                                        target="_blank"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faRuler}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Dobór rozmiaru
                                        </span>
                                    </a>
                                </div>
                                
                                <div className="col">
                                    <a
                                        href="https://medisorb.pl/wizard"
                                        target="_blank"
                                        style={menuElementContainer}
                                        className="dash-card"
                                    >
                                        <FontAwesomeIcon
                                            icon={faBandAid}
                                            inverse
                                        />
                                        <span style={fontStyle}>
                                            Dobór opatrunku specjalistycznego
                                        </span>
                                    </a>
                                </div>
                            </div>
                        )}

                        {PermissionHandler(
                            permissions,
                            'Dashboard',
                            'ContactForm'
                        ) && (
                            <div className="row">
                                <div className="col">
                                    <ContactFormModal
                                        icon = {faMailBulk}
                                        fontStyle = {fontStyle}
                                        menuElementContainer = {menuElementContainer}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </CardContent>
            {PermissionHandler(
                permissions,
                'Dashboard',
                'InfoRow'
            ) && data.partnerName != "TZMO" && (
                <>
                <h6 style={{ textAlign:'center' }}><span style={{ color:'#d4731c', fontWeight:'bold' }}>WAŻNE!</span></h6> <h6 style={{ textAlign:'center' }}> Kupujac na platformie tzmoonline.pl otrzymują Państwo <span style={{ fontWeight:'bold' }}>dodatkowy 1% rabatu na produkty TZMO SA.</span>  Rabat doliczany jest do aktualnie obowiązujących warunków handlowych.<br/> <span style={{fontSize:'0.75rem'}}> *nie dotyczy produktów oznaczonych HIT CENOWY</span> </h6>
                </>
            )}
            </Card>

        </>
    ) : null
}

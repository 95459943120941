import React, { useEffect, useState } from 'react'

const InfoPopup = (props) => {
    const [display, setDisplay] = useState(true)

    useEffect(()=>{
        if(display){
            setTimeout(()=>{
                onClosing()
            },30000)
        }
    },[])

    const onClosing = () => {
        setDisplay(false)
        localStorage.setItem('infoPopupShowed14112024', true)
    }

    return (
        (
            <div
                style={{
                    display: display ? 'block' : 'none',
                }}
                className="InfoPopupBg"
            ></div>
        ) && (
            <div
                style={{
                    display: display ? 'block' : 'none',
                }}
                className="InfoPopup"
            >
                <a
                    href="#/"
                    onClick={() => {
                        onClosing()
                    }}
                >
                    <img
                        alt={'obnizka-seni'}
                        src={'/img/popup/komunikat.png'}
                        style={{width:600}}
                    />
                </a>
                <img
                    alt={'zamknij'}
                    onClick={() => {
                        onClosing()
                    }}
                    src={'/close.png'}
                    height="25"
                    className="InfoPopupClose"
                />
            </div>
        )
    )
}

export default InfoPopup
